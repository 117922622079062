import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from 'gatsby'


const WidgetCursos = () => {
  const data = useStaticQuery(graphql`
    query WidgetCursosQuery {
      allCursosYaml {
        edges {
          node {
            curso
            content{
              titulo
            }
          }
        }
      }
    }
  `)
  const qy = data.allCursosYaml.edges;
  return (
    <div class="col-lg-4 col-md-6 single-footer-widget">
      <h4>Cursos</h4>
      <ul>
        {qy.map((dt, index) => {  return(
          <li><Link to={"/cursos/"+dt.node.curso}>{dt.node.content.titulo}</Link></li>
        )})}
        
      </ul>
    </div>
)};


export default WidgetCursos;