import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import WidgetCursos from "./WidgetCursos";

import "../styles/bootstrap.css"
import "../styles/flaticon.css"
import "../styles/themify-icons.css"
import "../styles/style.css"

export default function Layout({ children, breadcrumbs, color, item}) {
    const [isAberto, setAberto] = useState(false)
    function abrirfechar(){
        setAberto(!isAberto)
    }
  return (
    <>
        <Helmet>
        <title>
            VitalNet
            {breadcrumbs ? ` - ${breadcrumbs.join(" - ")}` : ``}
        </title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        </Helmet>
        <header className={ color ? ("header_area white-header") : ("header_area")}>
            <div class="main_menu">
                
                <nav className={["navbar","navbar-expand-lg","navbar-light",isAberto?"navopen":""].join(' ')}>
                <div class="container">
                    <Link className="navbar-brand logo_h" to="/">
                        <img src={ color ? ("/img/logo_b.svg") : ("/img/logo_c.svg")} alt="" width="130"/>
                    </Link>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={()=>abrirfechar()}
                    >
                        <span class="icon-bar"></span> <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <div
                        className={["navbar-collapse","offset",isAberto?"":"collapse"].join(' ')}
                        id="navbarSupportedContent"
                    >
                    <ul class="nav navbar-nav menu_nav ml-auto">
                        <li className={"nav-item"+ (item==0?" active":"")}>
                            <Link className="nav-link" to="/">Início</Link>
                        </li>
                        <li className={"nav-item submenu dropdown"+ (item==1?" active":"")}>
                        <Link
                            to="/sobre"
                            className="nav-link dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >Institucional</Link>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><Link className="nav-link" to="/sobre">Sobre nós</Link></li>
                            <li class="nav-item"><Link className="nav-link" to="/franquias">Seja Franqueado</Link></li>
                            <li class="nav-item"><Link className="nav-link" to="/carreiras">Trabalhe Conosco</Link></li>
                        </ul>
                        </li>
                        <li className={"nav-item"+ (item==2?" active":"")}>
                           <AnchorLink className="nav-link" to="/#cursos">Cursos</AnchorLink>
                        </li>
                        <li className={"nav-item"+ (item==3?" active":"")}>
                            <Link className="nav-link" to="/contato">Fale conosco</Link>
                        </li>
                        <li className="nav-item">
                            <a class="nav-link" href="https://ead.vital.net.br">VitalEAD</a>
                        </li>                
                    </ul>
                    </div>
                </div>
                </nav>
            </div>
        </header>
            <main>{children}</main>
        <footer class="footer-area section_gap">
            <div class="container">
               <div class="row">
                    <WidgetCursos />
            <div class="col-lg-3 col-md-6 single-footer-widget">
            <h4>A VitalNet</h4>
            <ul>
                <li><Link to="/sobre">Sobre</Link></li>
                <li><Link to="/carreiras">Trabalhe conosco</Link></li>
                <li><Link to="/franquias">Seja franqueado</Link></li>
            </ul>
            </div>
            <div class="col-lg-3 col-md-6 single-footer-widget">
            <h4>Contato</h4>
            <ul>
                <li><Link to="/contato">Fale Conosco</Link></li>
                <li>Rua Guaíra 3170, Guarapuava</li>
                <li>(42) 3035 3456</li>
            </ul>
            </div>

        </div>
                <div class="row footer-bottom d-flex justify-content-between">
            <p class="col-lg-8 col-sm-12 footer-text m-0 text-white">
            © {(new Date().getFullYear())} VitalNet
            </p>
            <div class="col-lg-4 col-sm-12 footer-social">
            <a target="_blank" href="https://www.facebook.com/vitalnetbr"><i class="ti-facebook"></i></a>
            <a target="_blank" href="https://www.instagram.com/vitalnetgp/"><i class="ti-instagram"></i></a>
            <Link to="/contato"><i class="ti-email"></i></Link>
            </div>
        </div>
            </div>
        </footer>
    </>
  )
}